<template>
  <!-- 成长值设置 -->
  <div class="content_body font_14">
    <div
      class="nav_title font_15 border_box"
      style="border-bottom: 3px solid #e4e4e4"
    >
      会员成长值规则设置
    </div>
    <div
      class="
        back_f8
        padlt_10
        padrt_20
        marbm_15
        dis_flex
        flex_y_center flex_x_between
      "
    >
      <div class="label dis_flex flex_y_center" style="margin-top: 0">
        启用成长值
      </div>
      <el-switch v-model="Active" @change="setActive"></el-switch>
    </div>
    <div class="color_999 padlt_20 line_26 marbm_15">
      启用后，会员等级随成长值变化，可针对单个会员手动调整和锁定等级<br />不启用，则会员等级手动调整
    </div>
    <!-- 配置模块 -->
    <div v-if="Active">
      <div class="padlt_20 marbm_15">
        <div class="marbm_15">
          来店一次产生成长值
          <el-input
            type="number"
            class="width_120"
            v-model.number="EnterEntityNumber"
            size="small"
          ></el-input>
          点/次
          <span style="color: red">（预约确认到店，销售，消耗，补欠款，退款）</span>
        </div>
        <div>
          完成预约产生成长值
          <el-input
            type="number"
            class="width_120"
            v-model.number="BookingNumber"
            size="small"
          ></el-input>
          点/次
          <span style="color: red">（取消预约会扣除）</span>
        </div>
      </div>
      <el-table :data="tableData" class="border_left border_right">
        <el-table-column label="商品类型" prop="goodsType">
          <template slot="header">
            <div class="text_center">商品类型</div>
          </template>
          <template slot-scope="scope">
            <div class="text_center">
              {{ scope.row.goodsType }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="门店销售现金支付1元产生成长值"
          prop="StoreSales"
        >
          <template slot-scope="scope">
            <el-input
              v-model.number="scope.row.SalePayNumber"
              size="small"
              class="inputValue"
              clearable
              onkeyup="this.value=this.value=this.value.replace(/\D/g,'')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="门店销售卡扣1元产生成长值" prop="CardDeduction">
          <template slot-scope="scope">
            <el-input
            :disabled="scope.row.disable"
              v-model.number="scope.row.SaleCardNumber"
              size="small"
              class="inputValue"
              clearable
              onkeyup="this.value=this.value=this.value.replace(/\D/g,'')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="门店销售赠送卡扣1元产生成长值" prop="giving">
          <template slot-scope="scope">
            <el-input
            :disabled="scope.row.disable"
              v-model.number="scope.row.SaleCardLargessNumber"
              size="small"
              class="inputValue"
              clearable
              onkeyup="this.value=this.value=this.value.replace(/\D/g,'')"
            ></el-input>
          </template>
        </el-table-column>
        <!-- <el-table-column label="网店销售现金支付1元产生成长值" prop="payments">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.payments" size="small" class="inputValue" clearable onkeyup="this.value=this.value=this.value.replace(/\D/g,'')"></el-input>
            </template>
          </el-table-column> -->
        <!-- <el-table-column label="消耗现金支付1元产生成长值" prop="consumption">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.consumption" size="small" class="inputValue" clearable onkeyup="this.value=this.value=this.value.replace(/\D/g,'')"></el-input>
            </template>
          </el-table-column> -->
      </el-table>
      <div class="text_center martp_25">
        <el-button type="primary" size="small" class="font_16" @click="bindSave"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/CRM/Customer/GrowthValue";
export default {
  name: "GrowthValue",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      Active: false, //成长值
      EnterEntityNumber: "", //来店一次
      BookingNumber: "", //完成预约
      tableData: [
        {
          goodsType: "产品",
          SalePayNumber: "",
          SaleCardNumber: "",
          SaleCardLargessNumber: "",
        },
        {
          goodsType: "项目",
          SalePayNumber: "",
          SaleCardNumber: "",
          SaleCardLargessNumber: "",
        },
        {
          goodsType: "储值卡",
          SalePayNumber: "",
          SaleCardNumber: "",
          SaleCardLargessNumber: "",
          disable: true,
        },
        {
          goodsType: "通用次卡",
          SalePayNumber: "",
          SaleCardNumber: "",
          SaleCardLargessNumber: "",
        },
        {
          goodsType: "时效卡",
          SalePayNumber: "",
          SaleCardNumber: "",
          SaleCardLargessNumber: "",
        },
        {
          goodsType: "套餐卡",
          SalePayNumber: "",
          SaleCardNumber: "",
          SaleCardLargessNumber: "",
        },
      ],
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    //保存
    bindSave() {
      const { EnterEntityNumber, BookingNumber, Active, tableData } = this;
      var params = {
        EnterEntityNumber,
        BookingNumber,
        Active,
        growthValueProductAddForm: tableData[0],
        growthValueProjectAddForm: tableData[1],
        growthValueSavingCardAddForm: tableData[2],
        growthValueGeneralCardAddForm: tableData[3],
        growthValueTimeCardAddForm: tableData[4],
        growthValuePackageCardAddForm: tableData[5],
      };
      API.add(params).then((res) => {
        if (res.StateCode == 200) {
          this.$message.success("保存成功!");
          this.GroupList();
        }
      });
    },
    //查询
    GroupList() {
      API.list().then((res) => {
        if (res.StateCode == 200) {
          var data = res.Data;
          this.Active = data.Active;
          this.BookingNumber = data.BookingNumber;
          this.EnterEntityNumber = data.EnterEntityNumber;
          this.tableData = new Array(
           Object.assign(data.growthValueProductOutForm,{goodsType:"产品"}),
            Object.assign(data.growthValueProjectOutForm,{goodsType:"项目"}),
            Object.assign(data.growthValueSavingCardOutForm,{goodsType:"储值卡",disable:true}),
            Object.assign(data.growthValueGeneralCardOutForm,{goodsType:"通用次卡"}),
            Object.assign(data.growthValueTimeCardOutForm,{goodsType:"时效卡"}),
            Object.assign(data.growthValuePackageCardOutForm,{goodsType:"套餐卡"})
          );
        }
      });
    },
    setActive(bool){
      if (!bool) {
        this.bindSave();
      }
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.GroupList();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
.nav_title {
  padding: 20px;
  width: calc(100% + 30px);
  transform: translate(-15px, -15px);
}
.label::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 15px;
  margin: 10px 10px;
  background-color: #018dff;
}
.width_120 {
  width: 120px;
}
.inputValue {
  width: 120px;
}
</style>
